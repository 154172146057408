import React from "react";
import { graphql } from "gatsby";
// import Img from "gatsby-image";

import { GridGallery } from "../../components/gallery";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import "../../css/plans.css";
// import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import paradeAwardsOpt from "../../images/parade-awards-opt.jpg";
import { FaTrophy, FaCrown, FaAward, FaMedal } from 'react-icons/fa';

class ParadePage extends React.Component {
  render(){
    const {data} = this.props;
    const gallery = data.contentfulGalleryPage;

    return (
      <Layout>
        <SEO title="2018 Parade Home" />
        <div className="container">

          <div className="row">
            <div className="col-sm mt-auto">
              <h1 className="mt-4 mb-0">2018 Parade Home</h1>
            </div>
            <div className="col-sm-6 text-right mt-auto mb-2">
              {/* <button type="button" className="btn ml-auto" (click)="filter('type', 'All')" [ngclass]="filterClass('All')">All</button>
              <button type="button" className="btn ml-1" (click)="filter('type', '2 Story')" [ngclass]="filterClass('2 Story')">2 Story</button>
              <button type="button" className="btn ml-1" (click)="filter('type', 'Rambler')" [ngclass]="filterClass('Rambler')">Ramblers</button>
              <button type="button" className="btn ml-1" (click)="filter('bonus', true)" [ngclass]="filterClass('bonus')">Bonus Room</button> */}
            </div>
          </div>

          <Row>
            <Col>

              <h4 className="mt-3">"The Eden" Plan<br /><small>3026 sqft</small></h4>

              <h5 className="mt-3 mb-3">Awards</h5>
              <div><FaCrown /> Best of The Wasatch 2018 Award</div>
              <div><FaTrophy /> Best Curb Appeal Award</div>
              <div><FaAward /> Best Landscaping Award</div>
              <div><FaMedal /> Best Floor Plan Award</div>
            </Col>
            <Col>
              <img src={paradeAwardsOpt} className="img-fluid" alt="Awards"/>
            </Col>
          </Row>

          <Row className="row mt-3 mb-4">
            <Col>
              <GridGallery images={gallery.gallery} gid="galleryGrid"/>
            </Col>
          </Row>


        </div>

      </Layout>
    )
  }
};

export default ParadePage;

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
      author
    }
  }
  contentfulGalleryPage(contentfulid: {eq: 2}) {
      id
      gallery {
        fluid {
          ...GatsbyContentfulFluid
        }
        file {
          url
          fileName
          contentType
        }
      }
    }
  }`
